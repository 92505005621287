<script>
import Base from '@backend/Base.vue';
export default {
    extends: Base,
    data() {
        return {
            Name: "BoDashboard",
            site_name: "",
            resData: {},
            contact: {},
            register: {},
        }
    },
    computed: {
        ufullname() {
            return this.user ? this.user.bu_full_name : "-"
        }
    },
    mounted() {
        this.$set(this.$root, 'page', this)
        this.refreshData()
    },
    methods: {}
};
</script>
<template>
<div class="container-fluid">
<PageTitle></PageTitle>
<div class="row">
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Howdy {{ufullname}}</h5>
                <br> Welcome to the <strong>{{site_name}}</strong> Website Administrator Page
                <br>You are on the PROTECTED page of the <strong>{{site_name}}</strong> Website Content Management
                system.<br><br>
                <p> This page is specifically intended ONLY for website administrators who will manage content.<br>
                    Before making arrangements, there are few things to note: <br><br>
                    1. Save and secure your Username and Password to avoid undesirable things.<br>
                    2. Prepare the material or content that will be updated first.<br>
                    3. Also prepare photos, videos or other related material, to facilitate the updating process.<br>
                    4. If there are questions about using features in this backoffice, you can ask the web developer
                    contact below.<br>
                    5. Use the latest version of the browser to get the compatibility features in this backoffice.<br></p>
                </div>
            </div>
        </div>
    </div>
    
</div>
</template>